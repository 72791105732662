export default class {
    constructor() {
        this.telInput = document.querySelector('.js-tel-input');
        this.numInput = document.querySelectorAll('.js-number');
        this.formTag = document.querySelector('form');
        this.zipInput = document.querySelector('[name="zip"]');
        this.addInput = document.querySelector('[name="add"]');
        this.init();
        this.inputNum();
        this.zip();
    }
    init() {
        const telInput = this.telInput;
        if (telInput) {
            telInput.setAttribute('inputmode','tel');
        }
    }
    inputNum() {
        const numInput = this.numInput;
        if (numInput) {
            numInput.forEach(element => {
                element.setAttribute('inputmode','numeric');
            });
        }
    }
    zip() {
        //住所入力が1箇所の想定です
        const formTag = this.formTag;
        const zipInput = this.zipInput;
        const addInput = this.addInput;
        if (zipInput) {
            formTag.classList.add('h-adr');
            formTag.insertAdjacentHTML('afterbegin','<input type="hidden" class="p-country-name" value="Japan">');
            zipInput.classList.add('p-postal-code');
            addInput.classList.add('p-region','p-locality','p-street-address','p-extended-address');
        }

    }
}