import Headroom from "headroom.js";

export default class {
    constructor() {
        this.init();
    }
    init() {
        const header = document.getElementById('js-header');
        //const gnav = document.getElementById('js-gnav');
        //const hamburger = document.getElementById('js-drawer__hamburger');

        if(header) {
            const headroomHeader = new Headroom(header);
            headroomHeader.init();
        }
        //if(gnav) {
        //    const headroomGnav = new Headroom(gnav);
        //    headroomGnav.init();
        //}
        //if(hamburger) {
        //    const headroomHamburger = new Headroom(hamburger);
        //    headroomHamburger.init();
        //}
    }
}