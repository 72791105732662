export default class {
	constructor() {
			this.viewport = document.querySelector('meta[name="viewport"]');
			this.width_mobile = 360

			this.init();
	}
	init() {
			this.switchViewport();
			window.addEventListener('resize',() => {
					this.switchViewport();
			})
	}
	switchViewport() {
			const value = window.outerWidth > 360 ? 'width=device-width,initial-scale=1' : 'width=360';
			if (this.viewport.getAttribute('content') !== value) {
					this.viewport.setAttribute('content', value);
			}
	}
}