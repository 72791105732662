export default class {
	constructor() {
				this.tab = '.js-tab';
        this.triggerList = '.js-tab__trigger';
        this.triggerItem = '.js-tab__trigger-item';
        this.contentsList = '.js-tab__contents';
        this.contentsItem = '.js-tab__contents-item';

			this.init();
		}

    init() {
        const triggerItem = document.querySelectorAll(this.triggerItem);
        const contentsItem = document.querySelectorAll(this.contentsItem);
        if (this.tab) {
            for (let i = 0; i < triggerItem.length; i++) {
                triggerItem[i].addEventListener('click', toggle);
            }

            function toggle() {
                for (let i = 0; i < triggerItem.length; i++) {
                    triggerItem[i].classList.remove('is-active');
                }
                for (let i = 0; i < contentsItem.length; i++) {
                    contentsItem[i].classList.remove('is-active');
                }
                this.classList.add('is-active');
                //const aryTabs = Array.prototype.slice.call(triggerItem); IEに対応する場合
                const aryTabs = Array.from(triggerItem);
                const index = aryTabs.indexOf(this);
                contentsItem[index].classList.add('is-active');
            }

        }
    }
}