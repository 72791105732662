import { Luminous, LuminousGallery } from "luminous-lightbox";

export default class {
	constructor() {
		this.item = '.js-lightbox';
		this.init();
	}

	init() {
		const items = document.querySelectorAll(this.item)
		if (items) {
			new LuminousGallery(items);
		}
	}
}